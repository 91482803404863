export default {
  "": {
    "id": 13374,
    "temp_key": "",
    "site_id": 32334,
    "name": "",
    "items": [
      {
        "id": "1o1w6jxd3dyw",
        "use": "page",
        "name": "Místo a čas",
        "page": "/",
        "section": "informace-2"
      },
      {
        "id": "bhm4it7k4mk8",
        "use": "page",
        "name": "Důležité informace",
        "page": "/",
        "section": "program"
      },
      {
        "use": "page",
        "id": "skuv98kvl7ae",
        "name": "Potvrzení účasti"
      }
    ]
  }
}