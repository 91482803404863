import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="pos--fix --spacing5 --size-M --transparent" type={"SiteHeader"} fixed={true} layout={"2"} transparent={true} backgroundColor={"var(--color-custom-1)"} social={null} title={{"color":"rgba(255,255,255,1)","className":""}} button={{"className":"btn-box"}} item={{"color":"rgba(255,255,255,1)","colorHover":"rgba(255,255,255,1)","className":"ff--2 fs--20 w--300"}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}