/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, ColumnCover, ColumnDiv, Subtitle, FullmapCover, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-jb858t css-1w9qoaz --full pb--30 pt--50" name={"uvod"} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40952/c3f7d99c5fa646739f83927147472364_s=3000x_.jpeg);
    }
  
background-position: 49% 55%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--220" content={"<span style=\"color: white\">V&amp;V</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 w--300 mt--30" href={"#kontakt"} content={"Potvrdit účast"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"informace"} style={{"backgroundColor":"rgba(44,63,130,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86 w--300 ls--0" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">Bude svatba!&nbsp;</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"Přijďte to s námi oslavit!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"informace-2"} style={{"backgroundColor":"rgba(44,63,130,1)"}} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--0 flex--center" style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40952/a9c3ee8f0ba64f1d9a11f049a7ace5d6_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40952/a9c3ee8f0ba64f1d9a11f049a7ace5d6_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/40952/a9c3ee8f0ba64f1d9a11f049a7ace5d6_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/40952/a9c3ee8f0ba64f1d9a11f049a7ace5d6_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/40952/a9c3ee8f0ba64f1d9a11f049a7ace5d6_s=1400x_.jpeg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box title-box--center fs--154" content={"<span style=\"color: white\">26.4.</span>"}>
                    </Title>

                    <Title className="title-box fs--62 mt--02" content={"<span style=\"color: white\">od 12:00</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40952/50da27a133e443e09739706626b99690_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/40952/50da27a133e443e09739706626b99690_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/40952/50da27a133e443e09739706626b99690_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/40952/50da27a133e443e09739706626b99690_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/40952/50da27a133e443e09739706626b99690_s=1400x_.jpeg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--43" content={"<span style=\"color: white\">Kostelík sv.</span>"}>
                    </Title>

                    <Title className="title-box fs--128 mt--02 pl--0 pr--0" content={"<span style=\"color: white\">Jana v Modré<br></span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--80" name={"program"} style={{"backgroundColor":"rgba(44,63,130,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-custom-1--95);\">Harmonogram</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"caret-color: rgb(40, 50, 70); color: var(--color-custom-1--95);\">Obřadem to začne, potom spíš freestyle</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--0 pt--0 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mb--0 mt--0 pb--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"program-3"} style={{"backgroundColor":"rgba(44,63,130,1)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":1300}} columns={"3"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"<span style=\"color: var(--color-custom-1--95);\">12:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Obřad<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"<span style=\"color: var(--color-custom-1--95);\">14:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Oběd<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"<span style=\"color: var(--color-custom-1--95);\">18:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Večerní raut"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(44,63,130,1)"}} name={"infor"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--color-custom-1--95);\">Důležité informace</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1--95);\">Kde to hodláme spáchat?</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">Obřad proběhne v <span style=\"font-weight: bold;\">Kostelíku sv. Jana v Modré</span><br>Zaparkovat můžete na parkovištích pro veřejnost u Archeoskanzenu.</span><span style=\"color: var(--black);\">&nbsp;</span><br>"}>
              </Text>

              <FullmapCover place={"Kostelík svatého jana modrá"} zoom={"13"}>
              </FullmapCover>

              <Text className="text-box" style={{"maxWidth":""}} content={"<br><span style=\"color: var(--color-custom-1--95);\">Svatební hostina bude ve <span style=\"font-weight: bold;\">Slovácké hospodě v Hradčovicích<br></span>Parkování u hospody a v přilehlém okolí.&nbsp;</span><br>"}>
              </Text>

              <FullmapCover place={"Slovácká hospoda"} zoom={"14"}>
              </FullmapCover>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1--95);\"><br>Ubytování</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">Ubytování je možné přímo ve Slovácké hospodě. Případně v přilehlém okolí. Pokud máte zájem o ubytování dejte nám vědět :-).&nbsp;</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1--95);\">Dresscode</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--95);\">Žádný není:-).. Hlavně přijďte, klidně i oblečení, zejména v čem je vám to pohodlné..</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1--95);\">Svatební dary</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"caret-color: rgb(245, 243, 227); color: rgb(245, 243, 227);\">Budeme rádi, když přijedete, dárky neřešte. Pokud vám to ale nedá, budeme moc rádi za drobný příspěvek na svatební cestu nebo nějaký vyrobený osobní dárek :-)</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"program"} style={{"backgroundColor":"rgba(44,63,130,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pt--12" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":1080}} content={"<span style=\"color: var(--color-custom-1--95);\">Obdrželi jste pozvánku? Potvrďte svou účast!</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <ContactForm className="--shape2 fs--22" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Jméno nebo jména (páry, rodinka...)","type":"text","required":true,"placeholder":"","id":"ypxqac30wvfo"},{"id":"m1x2p9kso08","type":"select","name":"Ubytování?","options":[{"id":"n1bmjqq34vid","value":"Nepotřebujeme"},{"id":"nisp4lf5l8vq","value":"Rádi bychom ve Slovácké hospodě"},{"id":"f6aav4gwinw","value":"zajistíme si sami "}]},{"id":"teamm7rlrzue","type":"email","name":"Email"},{"name":"Telefonní číslo (nepovinné)","type":"text","placeholder":"Telefon +420","id":"2fn4c8g48nrl","required":false},{"id":"3qmmi3rom5bp","type":"textarea","name":"Nějaké speciální přání nebo poznámka?"},{"name":"Odeslat","type":"submit","id":"s6oy53f1oqi6"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/78/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/78/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/78/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/78/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/78/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/78/img-1_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/78/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/78/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/78/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/78/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/78/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/78/img-2_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/78/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/78/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/78/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/78/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/78/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/78/img-3_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Těšíme se na Vás!</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--12" style={{"maxWidth":880}} content={"<span style='color: var(--color-dominant)'>Vytvořeno přes <a style='color:#fff' href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}